import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { ScrollReveal } from "../components/common"
import { SanityPortableText } from "../components/common/sanity"
import { PageSpacer } from "../components/pageBlocks/utils/pageSpacer"
import { ProjectFeed } from "../components/pageBlocks/projects/projectFeed"

import SEO from "../components/global/seo"

const StyledStandardText = styled(ScrollReveal)`
  padding: nav {
    display: flex;
    margin: 1rem 0;
  }
`

const ProjectPortal = ({ data: { page, projects } }) => {
  const { introText } = page || {}
  return (
    <>
      <SEO
        title="Projects"
        description="From new builds to re-clads and renovations, we’ve proudly built homes across Auckland."
      />
      <PageSpacer size={50} headerSpacer />
      <StyledStandardText container>
        <SanityPortableText text={introText} />
      </StyledStandardText>
      <ProjectFeed items={projects.nodes} />
    </>
  )
}

export default ProjectPortal

export const pageQuery = graphql`
  query projectPortalQuery {
    page: sanityProjectPortal {
      introText {
        _rawText(resolveReferences: { maxDepth: 5 })
      }
    }
    projects: allSanityProjectPage(
      filter: { hidden: { ne: true } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        projectName
        projectType
        slug {
          current
        }
        featureImages {
          asset {
            fluid(maxWidth: 1440) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
