import React from "react"
import styled from "styled-components"
import is from "styled-is"

// Styles
const StyledPageSpacer = styled.div`
  padding-bottom: ${props => props.theme.padding.vMobile};
  transition: all 0.2s ease;
  ${is("headerSpacer")`
    margin-top: 80px;
  `}
  @media ${props => props.theme.breakpoints.medium} {
  ${is("headerSpacer")`
    margin-top: 240px;
  `}
    padding-bottom: calc(
      ${props => `${props.size}px`} + 160 * (100vw - 320px) / 2180
    );
  }
`

export const PageSpacer = ({ size, headerSpacer }) => {
  return <StyledPageSpacer size={size} headerSpacer={headerSpacer} />
}
