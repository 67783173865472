import React from "react"
import styled from "styled-components"

import { ProjectPreview } from "./projectPreview"

const StyledProjectFeed = styled.div`
  display: grid;
  gap: 4rem 0;
  padding: 6rem 0;
`

export const ProjectFeed = ({ items }) => {
  return (
    <StyledProjectFeed>
      {items.map(item => (
        <ProjectPreview {...item} />
      ))}
    </StyledProjectFeed>
  )
}
