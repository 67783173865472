import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"
import { match } from "styled-is"
import { useLocation } from "@reach/router"

// Import common components
import { StandardCarousel } from "../../common"
import { StyledHeading } from "../../common"

// Import SVG
import ArrowLeft from "../../../svg/arrow-left.svg"
import ArrowRight from "../../../svg/arrow-right.svg"

// Styles
const StyledImageCarouselItem = styled.div`
  max-width: 66%;
  width: 100%;

  @media ${props => props.theme.breakpoints.large} {
    height: 561px;
    max-width: 841px;
  }

  flex: 0 0 auto;
  margin-right: 25px;
  user-select: none;
  position: relative;
  opacity: 0.5;
  transition: opacity 0.5s ease;
  &.is-selected {
    opacity: 1;
  }

  div {
    max-height: 50vw !important;
    aspect-ratio: 16 / 10;

    @media ${props => props.theme.breakpoints.large} {
      height: 561px;
      max-width: 841px;
      aspect-ratio: unset;
    }
  }

  img {
    height: 50vw;
    width: 100%;

    @media ${props => props.theme.breakpoints.large} {
      height: 561px;
      max-width: 841px;
    }
  }

  section {
    position: relative;
    max-width: 841px;
    width: 100%;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: ${props => props.theme.colors.text.pullColor};
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: 1;
      opacity: 0;
      transition: all 0.5s 0.8s ease;
    }
    > ${StyledHeading} {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      position: absolute;
      z-index: 2;
      transform: translateY(40px);
      opacity: 0;
      transition: all 0.5s 0s ease;
    }
  }
  a:hover {
    section {
      ${StyledHeading} {
        opacity: 1;
        transform: translateY(0);
        text-decoration: underline;
      }
      &:before {
        opacity: 0.8;
        transition-delay: 0s;
      }
    }
  }
`
const StyledImageCarousel = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

const StyledContainer = styled.div`
  position: relative;
`

const StyledCounter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem ${props => props.theme.padding.hMobile};
  h3 {
    font-size: 24px;
    color: #f0534c;
    line-height: 1.5;

    @media ${props => props.theme.breakpoints.medium} {
      font-size: 32px;
      color: #f0534c;
      line-height: 55px;
    }
  }

  p {
    font-size: 22px;
    color: #f0534c;
    line-height: 1.5;

    @media ${props => props.theme.breakpoints.medium} {
      font-size: 1;
    }
  }
  nav {
    display: none;
    align-items: center;
    gap: 0px 10px;
  }
  button {
    svg {
      width: 30px;
    }
  }

  @media ${props => props.theme.breakpoints.medium} {
    padding: 1rem ${props => props.theme.padding.hDesk};
    nav {
      display: flex;
    }
    button {
      svg {
        width: 70px;
      }
    }
  }
`

const ProjectCarouselItem = ({ asset, slug }) => {
  return (
    asset && (
      <StyledImageCarouselItem>
        <Link
          to={`/projects/${slug && slug.current}`}
          style={{ width: "100%" }}
        >
          <section>
            <StyledHeading as="h3">View Project</StyledHeading>
            <Img fluid={asset.fluid} />
          </section>
        </Link>
      </StyledImageCarouselItem>
    )
  )
}

export const ProjectPreview = ({
  featureImages,
  projectName,
  projectType,
  slug,
}) => {
  const [embla, setEmbla] = useState(null)
  const [prev, setPrev] = useState(false)
  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        if (embla.canScrollPrev()) {
          setPrev(true)
        } else {
          setPrev(false)
        }
      })
    }
  }, [embla])

  return (
    <StyledContainer>
      <StyledImageCarousel>
        <StandardCarousel padHorizontal emblaRef={setEmbla}>
          {featureImages.map(item => (
            <ProjectCarouselItem {...item} slug={slug} />
          ))}
        </StandardCarousel>
      </StyledImageCarousel>
      <StyledCounter>
        <div>
          <h3>{projectName}</h3>
          <p>{projectType}</p>
        </div>
        <nav>
          {prev && (
            <button
              onClick={() => {
                embla.scrollPrev()
              }}
              className="emb-prev"
            >
              <ArrowLeft />
            </button>
          )}
          <button
            onClick={() => {
              embla.scrollNext()
            }}
            className="emb-next"
          >
            <ArrowRight />
          </button>
        </nav>
      </StyledCounter>
    </StyledContainer>
  )
}
